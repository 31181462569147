export enum ROUTES {
  PLACE = '/place/:placeId',
  POST = '/post/:postId',
  EVENT = '/event/:eventId',
  EVENT2 = '/events/:eventId',
  PARTNER = '/partner/:partnerId',
  MEET_UP = '/meet-up/:meetupId',
  MEET_UP2 = '/meetup/:meetupId',
  NOT_FOUND = 'not-found',
  TABS = '/tabs',
  TAB = ':tab',
  SEARCH = '/search/:type',
  STANDALONE_SEARCH = '/standalone-search/:type',
  FEATURED_CONTENT = 'search/featured-content/:type',
}

// Routes which can be displayed in a regular browser, desktop or mobile
export const BROWSER_ENABLED_ROUTES = [
  /\/event\/\d+\/preview/,
  /\/place\/\w+\/preview/,
  /\/partner\/\w+\/preview/,
  /\/post\/\w+\/preview/,
  /\/register\.*$/,
  /\/download/,
  /\/public-events/,
];
