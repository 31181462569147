import {Box, Flex, useTheme} from '@chakra-ui/react';
import React from 'react';
import {TextG12, TextG14, TextG16, TextG20, TextG9} from '../../components/typography';
import {numberToCurrency} from '../../utils/price';
import styled from 'styled-components';
import {getMembershipColor} from '../../utils/membership';

const StyledList = styled.ul`
  list-style: none; /* Removes default bullet points */
  padding: 0;
  padding-left: 4px;
  margin: 0;
  margin-top: 6px;

  li {
    display: flex;
    align-items: center; /* Center-aligns the bullet with the text */
    position: relative;
    padding-left: 10px; /* Adds padding to make space for the custom bullet */
  }

  li::before {
    content: '•'; /* Custom bullet */
    position: absolute;
    left: 0; /* Aligns the bullet with the start of the list item */
  }
`;

// const StyledListItem = styled.li``

const MembershipHeaderCard = ({membership, selectedMembershipId, setSelectedMembershipId}) => {
  const theme = useTheme();
  const isSelected = selectedMembershipId == membership.id;

  if (!membership) return null;

  return (
    <Flex
      maxWidth="160px"
      zIndex={isSelected ? 99 : 1}
      transition="outline-color 0.3s ease, box-shadow 0.3s ease;"
      onClick={() => setSelectedMembershipId(membership.id)}
      outline={isSelected ? `2px solid ${getMembershipColor(membership)}` : '2px solid lightgrey'}
      borderRadius="18px"
      flexDir="column"
      px={4}
      py={5}
      background={!isSelected ? 'white' : membership.id == 1 ? '#F4FFFD' : '#F8F5FE'}
      boxShadow={isSelected ? '0px 4px 30px 0px #00000033' : ''}
      color={theme.colors.lightBlack}>
      <Flex minHeight="40px" alignItems="center">
        <TextG14 fontWeight="700">{membership.name}</TextG14>
      </Flex>
      <Flex mt="14px" alignItems="baseline">
        <TextG20 fontWeight="700">{numberToCurrency(membership.monthly_cost)}</TextG20>
        <TextG16 fontWeight="700">/mo</TextG16>
      </Flex>
      <TextG12 fontWeight="500" mt="12px" color={theme.colors.grey}>{`Approx ${numberToCurrency(
        membership.monthly_benefit_value
      )} Value`}</TextG12>
      <Box mt="12px" color="#374957" fontWeight="500">
        {membership.id == 1 ? (
          <>
            <TextG9 fontWeight="500">
              All the essentials needed to get fully plugged into your neighborhood.
            </TextG9>
          </>
        ) : (
          <>
            <TextG9 fontWeight="500">
              Insider access to the best your neighborhood has to offer.
            </TextG9>
          </>
        )}
      </Box>
    </Flex>
  );
};

export default MembershipHeaderCard;
