import React, {useEffect, useMemo} from 'react';
import {Flex, useTheme, Grid, GridItem, VStack, Box} from '@chakra-ui/react';
import useAuth from '../../../hooks/useAuth';
import ConvinceMeToGoButton from '../../../pages/Event/ConvinceMeToGoButton';
import {TextG10, TextG14} from '../../typography';
import {generatePath, Link, useNavigate, useParams} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {useTrackVisibility} from 'react-intersection-observer-hook';
import useVisibleArticles from '../../../hooks/useVisible';
import {useOffers} from '../../../hooks/useOffers';
import OffersCardBanner from '../../OffersCardBanner';
import NonPremiumCard from '../../Card/NonPremiumCard';
import DotSeparator from '../../DotSeparator';
import {Event} from '../../../types/acrticle';
import {getEventDate} from '../../../utils/date';
import DefaultEventImage from '../../../assets/images/default_event.png';
import {useFetchEventRSVPStats} from '../../../hooks/useEvent';
import {getDisplayPrice} from '../../../utils/price';
import Icon from '../../Icon';
import {storeScrollPositionInSession} from '../../../utils/scroll';
import {calculateAndGetDistance, getDistanceString} from '../../../utils/distance';
import useGetUserLocation from '../../../hooks/useUserLocation';
import CardBadge from '../../CardBadge';
import RSVPController from '../../../pages/Event/RSVP/RSVPController';
import {ConditionalWrapper} from '../../ConditionalWrapper';
import {BlueButton} from '../../button';
import UseablePassPill from './UseablePassPill';
import {useFetchUserTickets} from '../../../hooks/useUserTickets';
import {filter} from 'lodash';

const iconStyle = {
  color: 'grey',
  fontSize: '12px',
  marginRight: '5px',
};

const NonPremiumEventCard = ({
  event,
  leftTopItem,
  rightTopItem,
  fullCard = true,
  modal,
  isSlide = false,
  onClick,
  isPreview,
}: {
  event?: Event;
  leftTopItem?: any;
  rightTopItem?: any;
  showEndorsements?: boolean;
  fullCard?: boolean;
  height?: string;
  modal?: boolean;
  hideCheckin?: boolean;
  isSlide?: boolean;
  onClick?: (e) => void;
  isPreview?: boolean;
}) => {
  if (!event) return null;
  const theme = useTheme();
  const {user} = useAuth();
  const {pathname} = useParams();
  let userLocation;
  if (!isPreview) {
    userLocation = useGetUserLocation();
  }
  const isProduction = process.env.NODE_ENV === 'production';
  const [ref, {wasEverVisible, isVisible}] = useTrackVisibility();
  const {addVisibleArticle} = useVisibleArticles();
  const {data} = useFetchEventRSVPStats(event.id, !onClick && isVisible && !!user);
  const rsvp_stats = data?.data.rsvp_stats;
  const usersGoing = rsvp_stats?.GOING;
  //const usersGoing = 0;

  const eventDiscount = user?.activeMembershipPlan?.event_discount;
  const isExclusive = event?.communities && event?.communities.length > 0 && event.is_private;
  const navigate = useNavigate();

  const fetchTickets = useFetchUserTickets();
  const tickets = fetchTickets.data?.data;
  const eventTickets = filter(tickets, (ticket) => ticket.event_id == event.id);
  const userHasTickets = eventTickets && eventTickets.length > 0;

  useEffect(() => {
    if (wasEverVisible && event?.id.toString()) {
      addVisibleArticle({id: event.id.toString(), type: 'event'});
    }
  }, [wasEverVisible]);

  const eventDate = useMemo(
    () =>
      !!event.is_recurring
        ? event.recurring_schedule
        : getEventDate(event.start_date_time, event.end_date_time),
    [event]
  );

  const fetchOffers = useOffers({
    articleType: 'eventId',
    articleId: event.id.toString(),
    enabled: !onClick && isVisible && !!user,
  });

  const onClickConvinceMeToButton = (e) => {
    e.preventDefault();
  };

  const activeOffers = useMemo(() => {
    if (fetchOffers && fetchOffers.offers && fetchOffers.offers.length) {
      return <OffersCardBanner fullCard={fullCard} offers={fetchOffers.offers} />;
    }
  }, [fetchOffers, theme]);

  return (
    <Box w="100%">
      <ConditionalWrapper
        condition={!onClick}
        wrapper={(children) => (
          <Link
            style={{width: '100%'}}
            to={
              modal
                ? {pathname, search: `?id=${event.id.toString()}&articleType=event`}
                : generatePath(ROUTES.EVENT, {
                    eventId: event.id.toString(),
                  })
            }
            onClick={() => storeScrollPositionInSession()}
            ref={ref}>
            {children}
          </Link>
        )}>
        {event.is_walkabout_official ? (
          <CardBadge
            top={isExclusive ? '44px' : isSlide ? '12px' : '9px'}
            w="145px"
            label="WALKABOUT OFFICIAL"
            center="true"
          />
        ) : null}
        <NonPremiumCard
          onClick={(e) => {
            if (onClick) {
              onClick(e);
              return;
            }
          }}
          outline={event.is_walkabout_official ? '1px solid #FFC0C6' : 'none'}
          minHeight="100%"
          maxHeight="100%"
          isSlide={isSlide}
          height={'auto'}
          coverImage={event.image_url || DefaultEventImage}
          leftTopItem={!fullCard && activeOffers ? activeOffers : leftTopItem}
          rightTopItem={rightTopItem}
          childPaddingX={!fullCard ? 3 : 4}
          childMarginTop={event.is_walkabout_official ? '6px' : 0}
          article={{...event, type: 'event'}}>
          <Grid height="100%" alignContent="space-between">
            <GridItem>
              <VStack align="start">
                <TextG14
                  pr="30px"
                  isTruncated="&hellip;"
                  noOfLines={3}
                  fontWeight={700}
                  color={theme.colors.black}>
                  {event.title}
                </TextG14>
                {!isPreview && <UseablePassPill event={event} />}
                <Flex justifyContent={'space-between'}>
                  <Flex
                    py={1}
                    align="center"
                    textAlign="center"
                    width={usersGoing > 0 ? '80%' : '100%'}
                    flexWrap={'wrap'}>
                    {!!event.is_recurring && <Icon iconName="fi-rr-time-past" style={iconStyle} />}
                    <TextG10 color={theme.colors.black}>{eventDate}</TextG10>
                    {event.price && !event.price_override && (
                      <>
                        <DotSeparator color={theme.colors.black} />

                        <Flex gap="5px">
                          <TextG10
                            style={{wordWrap: 'break-word !important'}}
                            color={theme.colors.lightBlack}
                            as={
                              event.price > 0 &&
                              event.has_tickets &&
                              eventDiscount &&
                              eventDiscount > 0 &&
                              !event.price_override
                                ? 's'
                                : ''
                            }>
                            {getDisplayPrice(event.price, event?.max_price, event?.price_override)}
                          </TextG10>
                          {event.price > 0 &&
                            event.has_tickets &&
                            eventDiscount &&
                            eventDiscount > 0 &&
                            !event.price_override && (
                              <TextG10
                                color={theme.colors.lightBlack}
                                style={{wordWrap: 'break-word !important'}}>
                                {getDisplayPrice(event.price - (event.price * eventDiscount) / 100)}
                              </TextG10>
                            )}
                        </Flex>
                      </>
                    )}
                    {fetchOffers && fetchOffers.offers && fetchOffers.offers.length > 0 && (
                      <>
                        <DotSeparator color={theme.colors.black} />
                        <TextG10 py={1} color={theme.colors.rose}>
                          {`${fetchOffers.offers.length} ${
                            fetchOffers.offers.length == 1 ? 'Offer' : 'Offers'
                          }`}
                        </TextG10>
                      </>
                    )}
                    {event.latitude && event.longitude && userLocation?.data && (
                      <>
                        <DotSeparator color={theme.colors.grey} />
                        <TextG10 sx={{wordWrap: 'normal !important'}}>
                          {getDistanceString(
                            calculateAndGetDistance(
                              {
                                latitude: userLocation?.data?.latitude,
                                longitude: userLocation?.data?.longitude,
                              },
                              {latitude: Number(event.latitude), longitude: Number(event.longitude)}
                            )
                          )}
                        </TextG10>
                      </>
                    )}
                  </Flex>
                  {usersGoing > 0 && (
                    <Flex
                      py={1}
                      alignItems="center"
                      textAlign="center"
                      width={'20%'}
                      alignSelf={'start'}>
                      <TextG10
                        whiteSpace="nowrap"
                        style={{marginLeft: 'auto'}}>{`${usersGoing} Going`}</TextG10>
                    </Flex>
                  )}
                </Flex>
              </VStack>
            </GridItem>
            {!onClick && (
              <GridItem>
                <Flex
                  mt={1}
                  justifyContent="start"
                  align="center"
                  gap="5px"
                  color={theme.colors.black}>
                  {event.has_tickets && (
                    <BlueButton
                      w="120px"
                      height="30px"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        userHasTickets
                          ? navigate(`/tickets/${event.id}`)
                          : navigate(`/event/${event.id}/tickets`, {
                              replace: true,
                            });
                      }}>
                      <TextG10 fontWeight="500">
                        {userHasTickets ? 'See Tickets' : 'Get Tickets'}
                      </TextG10>
                    </BlueButton>
                  )}
                  <ConvinceMeToGoButton
                    event={event}
                    textColor={
                      event.has_tickets || event.rsvp_status?.status !== null ? 'black' : 'white'
                    }
                    background={event.has_tickets ? 'white' : '#276ACD'}
                    typographySize="sm"
                    onClick={onClickConvinceMeToButton}
                    buttonProps={{
                      flexGrow: 1,
                      height: '2rem',
                      border:
                        event.pass_types && event.pass_types[0]
                          ? '1px solid black'
                          : '1px solid white',
                    }}
                  />
                  {!event.has_tickets && (
                    <RSVPController
                      event={event}
                      buttonProps={{
                        flexGrow: 0.4,
                        height: '2rem',
                        border: '1px solid white',
                      }}
                      variant="sm"
                    />
                  )}
                </Flex>
              </GridItem>
            )}
          </Grid>
        </NonPremiumCard>
      </ConditionalWrapper>
    </Box>
  );
};

NonPremiumEventCard.defaultProps = {
  showEndorsements: true,
};

export default NonPremiumEventCard;
