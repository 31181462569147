import React from 'react';
import {createRoot} from 'react-dom/client';
import store from './state/store';
import {Provider} from 'react-redux';
import App from './App';
import './assets/icons/css/uicons-regular-rounded.css';
import './assets/icons/css/uicons-solid-rounded.css';
import './assets/icons/css/uicons-bold-rounded.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import * as Sentry from '@sentry/react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: process.env.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/dev-my\.walkabout\.app/,
    /^https:\/\/my\.walkabout\.app/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

document.addEventListener("DOMContentLoaded", function () {

  function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

	function isIOS() {
		return /iPhone|iPad|iPod/i.test(navigator.userAgent);
	}

  function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  function getCustomUrlScheme() {
    // Construct your custom URL scheme here
    // Example: walkabout://example.com/page?param=value
    const currentUrl = window.location.href;
    const customUrl = currentUrl.replace(/^https?:\/\//, 'walkabout://');
    return customUrl;
  }


  if (isMobile() && isSafari() && isIOS()) {
    const customUrl = getCustomUrlScheme();
    window.location.href = customUrl;
  }
});

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

serviceWorkerRegistration.register()
