import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {fetchMembershipStripLink} from '../endpoints/api';
import useAuth from '../hooks/useAuth';
import {isMobile} from '../utils/deviceCheck';

const EssentialsCheckout = () => {
  const {user} = useAuth();
  const [stripeLoading, setStripeLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (user?.hasActiveMembership) {
        navigate('/home');
        return;
      } else if (!(isMobile || window['ReactNativeWebView'])) {
        navigate('/download');
        return;
      }
      setStripeLoading(true);
      fetchMembershipStripLink(1).then((ret) => {
        if (ret.data.link) {
          window.location.href = ret.data.link;
        } else {
          setStripeLoading(false);
        }
      });
    }
  }, [user]);

  return <></>;
};

export default EssentialsCheckout;
