import {
  AbsoluteCenter,
  Box,
  Center,
  Flex,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {EventRSVPStatus, useFetchEvent} from '../../hooks/useEvent';
import {useFetchChatUsers, useLastChatVisit} from '../../hooks/useEvents';
import {Event} from '../../types/acrticle';
import {isIphone} from '../../utils/deviceCheck';
import Icon from '../Icon';
import {TextG14} from '../typography';
import ChatHeader from './ChatHeader/EventChatHeader';
import {CHAT_TABS} from './ChatHeader/ChatTabs';
import FeaturedTab from './FeaturedTab';
import RepliesTab from './RepliesMentionsTab';
import ThreadTab from './ThreadTab';
import {
  useAddCommentImage,
  useCreateEventComment,
  useDeleteCommentImage,
  useDeleteEventComment,
  useFetchRepliesAndMentions,
  useHelpfulComment,
  useInfiniteEventComments,
  useInfiniteFeaturedEventComments,
  useReportComment,
  useUpdateEventComment,
} from '../../hooks/useEventComments';
import useAuth from '../../hooks/useAuth';
import {useFetchCommunityChatUsers} from '../../hooks/useCommunityComments';
import {getImage} from '../../pages/Profile/Edit/CropImage';
import {ChatMedia} from './ChatComment';

const fetchCommentsHook = useInfiniteEventComments;

export const EventChatIsClosed = (event: Event) => {
  return dayjs.utc(event?.end_date_time + ':00').diff(dayjs.utc(), 'hour') <= -24;
};

const EventChat = () => {
  const {eventId, tab} = useParams();
  const {user} = useAuth();
  const fetchEvent = useFetchEvent(Number(eventId));
  const event = fetchEvent.data?.data;
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = CHAT_TABS.indexOf(decodeURIComponent(searchParams.get('tab') || 'Thread'));
  const [headerHeight, setHeaderHeight] = useState(0);
  const [tabIndex, setTabIndex] = useState(defaultTab == -1 ? 0 : defaultTab);
  const isRSVPd = !!(
    event?.rsvp_status.status_value &&
    event?.rsvp_status.status_value != EventRSVPStatus.NOT_INTERESTED
  );
  const eventChatClosed = EventChatIsClosed(event);
  const lastChatVisit = useLastChatVisit();

  const addEventComment = useCreateEventComment();
  const updateEventComment = useUpdateEventComment();
  const deleteEventComment = useDeleteEventComment();
  const reportEventComment = useReportComment();
  const helpfulEventComment = useHelpfulComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      const comment = await addEventComment.mutateAsync({
        eventId: event.id,
        content,
        parent_id,
        user,
        rsvp_status: event.rsvp_status.status_value as EventRSVPStatus,
        hasImage: imageURLs && imageURLs.length > 0,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (eventId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: eventId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      const comment = await updateEventComment.mutateAsync({
        eventId: event.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (eventId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: eventId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deleteEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulEventComment.mutateAsync({
      eventId: event.id,
      commentId,
    });
  };

  // to fix issue with overscroll on iOS
  useEffect(() => {
    if (isIphone) document.body.style.overflow = 'hidden';
    return () => {
      if (isIphone) document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (event && event.id) {
      lastChatVisit.mutateAsync({eventId: event.id});
      intervalId = setInterval(() => {
        lastChatVisit.mutateAsync({eventId: event.id});
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [event]);

  useEffect(() => {
    setSearchParams({['tab']: CHAT_TABS[tabIndex]}, {replace: true});
  }, [tabIndex]);

  if (!fetchEvent || fetchEvent.isLoading)
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Box>
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        lazyBehavior="keepMounted">
        <Flex>
          <ChatHeader
            addComment={addComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            reportComment={reportComment}
            helpfulComment={helpfulComment}
            event={event}
            setHeaderHeight={setHeaderHeight}
            tabIndex={tabIndex}
          />
          {eventChatClosed ? (
            <AbsoluteCenter
              w="95%"
              textAlign="center"
              color={theme.colors.grey}
              pt={`${headerHeight / 2}px`}>
              <Flex flexDir="column" gap={8}>
                <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
                <TextG14 fontWeight="500">This event has ended and the chat is closed.</TextG14>
              </Flex>
            </AbsoluteCenter>
          ) : !isRSVPd ? (
            <AbsoluteCenter
              w="95%"
              textAlign="center"
              color={theme.colors.grey}
              pt={`${headerHeight / 2}px`}>
              <Flex flexDir="column" gap={8}>
                <Icon iconName="fi-rr-comment" style={{fontSize: '48px'}} />
                <TextG14 fontWeight="500">
                  You must be RSVP'd to this event to participate in event chat.
                </TextG14>
              </Flex>
            </AbsoluteCenter>
          ) : (
            <TabPanels zIndex="auto" background="white">
              <TabPanel p={0}>
                <ThreadTab
                  name={event.title}
                  fetchUsersHook={useFetchChatUsers}
                  isRSVPd={isRSVPd}
                  addComment={addComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  reportComment={reportComment}
                  helpfulComment={helpfulComment}
                  articleId={event.id}
                  headerHeight={headerHeight}
                  fetchCommentsHook={fetchCommentsHook}
                />
              </TabPanel>
              <TabPanel p={0}>
                <RepliesTab
                  articleId={event.id}
                  fetchRepliesHook={useFetchRepliesAndMentions}
                  headerHeight={headerHeight}
                />
              </TabPanel>
              <TabPanel p={0}>
                <FeaturedTab
                  fetchUsersHook={useFetchChatUsers}
                  name={event.title}
                  isRSVPd={isRSVPd}
                  addComment={addComment}
                  updateComment={updateComment}
                  deleteComment={deleteComment}
                  reportComment={reportComment}
                  helpfulComment={helpfulComment}
                  fetchFeaturedCommentsHook={useInfiniteFeaturedEventComments}
                  articleId={event.id}
                  headerHeight={headerHeight}
                />
              </TabPanel>
            </TabPanels>
          )}
        </Flex>
      </Tabs>
    </Box>
  );
};

export default EventChat;
