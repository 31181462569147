import {
  AbsoluteCenter,
  Box,
  Center,
  Flex,
  Spinner,
  TabPanel,
  TabPanels,
  Tabs,
  useTheme,
} from '@chakra-ui/react';
import {filter} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {isIphone} from '../../utils/deviceCheck';
import Icon from '../Icon';
import {TextG14} from '../typography';
import ChatHeader from './ChatHeader/CommunityChatHeader';
import {CHAT_TABS} from './ChatHeader/ChatTabs';
import FeaturedTab from './FeaturedTab';
import RepliesTab from './RepliesMentionsTab';
import ThreadTab from './ThreadTab';
import {
  useAddCommentImage,
  useCreateCommunityComment,
  useDeleteCommentImage,
  useDeleteCommunityComment,
  useFetchCommunity,
  useFetchCommunityChatUsers,
  useFetchRepliesAndMentions,
  useHelpfulCommunityComment,
  useInfiniteCommunityComments,
  useInfiniteFeaturedCommunityComments,
  useLastChatVisit,
  useReportCommunityComment,
  useUpdateCommunityComment,
} from '../../hooks/useCommunityComments';
import {getImage} from '../../pages/Profile/Edit/CropImage';
import { ChatMedia } from './ChatComment';

const fetchCommentsHook = useInfiniteCommunityComments;

const CommunityChat = () => {
  const {communityId, tab} = useParams();
  const {user} = useAuth();
  const fetchCommunity = useFetchCommunity(Number(communityId));
  const community = fetchCommunity.data?.data;
  const theme = useTheme();
  let [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = CHAT_TABS.indexOf(decodeURIComponent(searchParams.get('tab') || 'Thread'));
  const [headerHeight, setHeaderHeight] = useState(0);
  const [tabIndex, setTabIndex] = useState(defaultTab == -1 ? 0 : defaultTab);

  const lastChatVisit = useLastChatVisit();

  const addCommunityComment = useCreateCommunityComment();
  const updateCommunityComment = useUpdateCommunityComment();
  const deleteCommunityComment = useDeleteCommunityComment();
  const reportCommunityComment = useReportCommunityComment();
  const helpfulCommunityComment = useHelpfulCommunityComment();
  const addCommentImage = useAddCommentImage();
  const deleteCommentImage = useDeleteCommentImage();

  const addComment = async (content: string, parent_id?: string, imageURLs?: Array<any>) => {
    if (content.length > 0) {
      const comment = await addCommunityComment.mutateAsync({
        communityId: community.id,
        content,
        parent_id,
        user,
        hasImage: imageURLs && imageURLs.length > 0,
      });

      if (imageURLs && imageURLs.length > 0 && comment) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);

          if (communityId && croppedImage) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: communityId,
              comment_id: comment.data.id,
            });
          }
        });
      }
    }
  };

  const updateComment = async (
    commentId: string,
    content: string,
    parent_id?: string,
    imageURLs?: Array<any>,
    mediaToDelete?: ChatMedia[]
  ) => {
    if (content.length > 0) {
      await updateCommunityComment.mutateAsync({
        communityId: community.id,
        commentId,
        content,
      });

      if (imageURLs && imageURLs.length > 0) {
        imageURLs.map(async (media) => {
          const croppedImage = await getImage(media, null);
          if (communityId && croppedImage && commentId) {
            await addCommentImage.mutateAsync({
              formData: croppedImage,
              target_id: communityId,
              comment_id: commentId,
            });
          }
        });
      }

      if (mediaToDelete && mediaToDelete.length > 0) {
        mediaToDelete.map(async (media) => {
          if (media.id) {
            await deleteCommentImage.mutateAsync({mediaId: media.id});
          }
        });
      }
    }
  };

  const deleteComment = async (commentId: string) => {
    await deleteCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };

  const reportComment = async (commentId: string) => {
    await reportCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };

  const helpfulComment = async (commentId: string) => {
    await helpfulCommunityComment.mutateAsync({
      communityId: community.id,
      commentId,
    });
  };

  // to fix issue with overscroll on iOS
  useEffect(() => {
    if (isIphone) document.body.style.overflow = 'hidden';
    return () => {
      if (isIphone) document.body.style.overflow = 'unset';
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (community && community.id) {
      lastChatVisit.mutateAsync({communityId: community.id});
      intervalId = setInterval(() => {
        lastChatVisit.mutateAsync({communityId: community.id});
      }, 5000);
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [community]);

  useEffect(() => {
    setSearchParams({['tab']: CHAT_TABS[tabIndex]}, {replace: true});
  }, [tabIndex]);

  if (!community || !user)
    return (
      <Center h="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Box>
      <Tabs
        outline={'none !important'}
        index={tabIndex}
        onChange={(index) => setTabIndex(index)}
        isLazy
        lazyBehavior="keepMounted">
        <Flex>
          <ChatHeader
            addComment={addComment}
            updateComment={updateComment}
            deleteComment={deleteComment}
            reportComment={reportComment}
            helpfulComment={helpfulComment}
            community={community}
            setHeaderHeight={setHeaderHeight}
            tabIndex={tabIndex}
          />

          <TabPanels zIndex="auto" background="white">
            <TabPanel p={0}>
              <ThreadTab
                name={community.name}
                articleId={community.id}
                isRSVPd={true}
                fetchUsersHook={useFetchCommunityChatUsers} //event ? useFetchChatUsers : useFetchCommunityChatUsers
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                reportComment={reportComment}
                helpfulComment={helpfulComment}
                headerHeight={headerHeight}
                fetchCommentsHook={fetchCommentsHook}
              />
            </TabPanel>
            <TabPanel p={0}>
              <RepliesTab
                articleId={community.id}
                fetchRepliesHook={useFetchRepliesAndMentions}
                headerHeight={headerHeight}
              />
            </TabPanel>
            <TabPanel p={0}>
              <FeaturedTab
                fetchUsersHook={useFetchCommunityChatUsers}
                name={community.name}
                isRSVPd={true}
                addComment={addComment}
                updateComment={updateComment}
                deleteComment={deleteComment}
                reportComment={reportComment}
                helpfulComment={helpfulComment}
                fetchFeaturedCommentsHook={useInfiniteFeaturedCommunityComments}
                articleId={community.id}
                headerHeight={headerHeight}
              />
            </TabPanel>
          </TabPanels>
        </Flex>
      </Tabs>
    </Box>
  );
};

export default CommunityChat;
