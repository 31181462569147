import {Box, Flex, Image, useTheme} from '@chakra-ui/react';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {TextG14} from '../typography';
import {getMembershipColor} from '../../utils/membership';

const MembershipBenefitsHomeButton = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {user} = useAuth();
  const membership = user?.activeMembershipPlan;

  return (
    <Box
      position="relative"
      background={getMembershipColor(user?.activeMembershipPlan)}
      color="white"
      px={1}
      py={3}
      borderRadius="10px"
      onClick={() => {
        if (membership) navigate(`/membership/${membership.id}`);
      }}>
      {/* {!isInCommunity && (
        <Box
          position="absolute"
          backgroundColor={theme.colors.blue}
          color="white"
          borderRadius="10px"
          top="10px"
          right="10px"
          px={2}
          py={0.5}>
          <TextG10>New</TextG10>
        </Box>
      )} */}
      <Flex alignItems="center" gap="12px" justifyContent="center">
        {/* {membershipImage && (
          <Image
            w="40px"
            h="40px"
            borderRadius="100%"
            objectFit="scale-down"
            src={membershipImage}
          />
        )} */}
        <TextG14 fontWeight="700">Quickly Access Your Benefits</TextG14>
      </Flex>
    </Box>
  );
};

export default MembershipBenefitsHomeButton;
