import {Center, Spinner, useTheme} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useFetchMembership} from '../../hooks/useMembershipPlans';
import MembershipAbout from './About';
import useAuth from '../../hooks/useAuth';
import SuccessModal from './SuccessModal';
import Header from './Header';
import useLocalStorage from '@rehooks/local-storage';

const Membership = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {membershipId} = useParams();
  const {data, isLoading} = useFetchMembership(Number(membershipId));
  const membership = data?.data;
  const [newMemberHomePulse, setNewMemberHomePulse] =
    useLocalStorage<boolean>('newMemberHomePulse');

  const {user, reFetchUserData} = useAuth();
  const [successModalOpen, setSuccessModalOpen] = useState(true);

  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!!searchParams.get('success')) {
      setSuccess(true);
      setNewMemberHomePulse(true);
      reFetchUserData();
    }
  }, [searchParams]);

  if (isLoading || !user)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <>
      <Header membership={membership} />
      <MembershipAbout />
      {success && (
        <>
          <SuccessModal
            membership={membership}
            isOpen={successModalOpen}
            setIsOpen={setSuccessModalOpen}
          />
        </>
      )}
    </>
  );
};

export default Membership;
