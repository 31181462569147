import {Center, Spinner, Flex, useTheme, Image, Box} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import BackButton from '../../components/BackButton';
import {useFetchMemberships} from '../../hooks/useMembershipPlans';
import FallbackImg from '../../assets/images/fallback.jpg';
import Header from './Header';
import {useNavigate} from 'react-router-dom';
import HeroImage from '../../assets/images/memberOptionsHero.png';
import {TextG10, TextG14} from '../../components/typography';
import MembershipBenefits from './MembershipBenefits';
import {BlueButton} from '../../components/button';
import {getMembershipColor} from '../../utils/membership';
import {fetchMembershipStripLink} from '../../endpoints/api';
import useAuth from '../../hooks/useAuth';

const Memberships = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const {data, isLoading} = useFetchMemberships();
  const memberships = data?.data;
  const [stripeLoading, setStripeLoading] = useState(false);
  const [membershipIdSelected, setMembershipIdSelected] = useState();
  const {user} = useAuth();
  const [isPreview, setIsPreview] = useState(true);

  useEffect(() => {
    if (memberships && memberships[0]) {
      setMembershipIdSelected(memberships[0].id);
    }
  }, [memberships]);

  useEffect(() => {
    if (user) setIsPreview(false);
  }, [user]);

  if (isLoading || stripeLoading)
    return (
      <Center style={{minHeight: '100vh'}}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor={theme.colors.lightGrey}
          color={theme.colors.lightBlue}
          size="lg"
        />
      </Center>
    );

  return (
    <Box overflow="hidden">
      <Box
        w="100%"
        h="100%"
        pos="absolute"
        zIndex={-1}
        height="567px"
        top="220px"
        background={
          membershipIdSelected == 1
            ? 'linear-gradient(180deg, #EDFFF9 0%, #FFFFFF 100%)'
            : 'linear-gradient(180deg, rgba(241, 234, 255, 0.43) 0%, #FFFFFF 100%)'
        }></Box>
      <Flex position="absolute" top="0" left="0" height="230px" w="100vw">
        <BackButton onClick={() => navigate('/home')} m={'.75rem'} pos="relative" />
        <Image
          fallbackStrategy="onError"
          fallbackSrc={FallbackImg}
          //   src={event?.image_url || DefaultEventImage}
          src={HeroImage}
          alt="cover image"
          position="absolute"
          width="100%"
          height="100%"
          objectFit="cover"
          zIndex="-1"
          borderBottomRadius="14px"
        />
        {/* <AbsoluteCenter textAlign="center" mt="-18px">
          <TextA24 fontWeight="700" color="white">
            Membership
            <br /> Options
          </TextA24>
          <TextG16 mt="12px" whiteSpace="nowrap" color="white">
            A sub-title for pricing page is here
          </TextG16>
        </AbsoluteCenter> */}
      </Flex>
      <Header
        memberships={memberships}
        selectedMembershipId={membershipIdSelected}
        setSelectedMembershipId={setMembershipIdSelected}
      />
      <MembershipBenefits
        memberships={memberships}
        selectedMembershipId={membershipIdSelected}
        setSelectedMembershipId={setMembershipIdSelected}
      />

      <Flex
        gap="10px"
        flexDir="column"
        w="100%"
        px={4}
        justifyContent="center"
        alignItems="center"
        mb="40px"
        mt="20px">
        {isPreview ? (
          <BlueButton
            onClick={() => {
              navigate('/soft-check?membershipPreview=true');
            }}
            transition="background 0.3s ease"
            w="100%"
            borderRadius="7px"
            background={getMembershipColor(
              membershipIdSelected == 1 ? memberships[0] : memberships[1]
            )}>
            <TextG14 fontWeight="500">Continue to Sign Up</TextG14>
          </BlueButton>
        ) : (
          <>
            <BlueButton
              onClick={() => {
                if (membershipIdSelected) {
                  setStripeLoading(true);
                  fetchMembershipStripLink(membershipIdSelected).then((ret) => {
                    if (ret.data.link) {
                      window.location.href = ret.data.link;
                    } else {
                      setStripeLoading(false);
                    }
                  });
                }
              }}
              transition="background 0.3s ease"
              w="100%"
              borderRadius="7px"
              background={getMembershipColor(
                membershipIdSelected == 1 ? memberships[0] : memberships[1]
              )}>
              <TextG14 fontWeight="500">Continue to Checkout</TextG14>
            </BlueButton>
            <TextG10>Billed monthly, cancel anytime</TextG10>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Memberships;
